import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  Message,
  Form,
  Button,
  Container,
  Header,
  // eslint-disable-next-line comma-dangle
  Input,
} from 'semantic-ui-react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const LOGIN_USER = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ok
      token
      refreshToken
      errors {
        message
        path
      }
    }
  }
`;

const Login = ({ history }) => {
  // state from mobx
  const state = useLocalStore(() => ({ email: '', password: '', errors: {} }));
  const [login] = useMutation(LOGIN_USER);

  const {
    email,
    password,
    errors: { emailError, passwordError },
  } = state;

  const onChange = ({ target }) => {
    const { name, value } = target;
    state[name] = value;
  };

  const onSubmit = async () => {
    const response = await login({
      variables: { email, password },
    });
    const { ok, refreshToken, token, errors } = response.data.login;
    if (ok) {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      state.errors = '';
      // eslint-disable-next-line react/prop-types
      history.push('/');
    } else {
      const err = {};
      errors.forEach(({ path, message }) => {
        err[`${path}Error`] = message;
      });
      state.errors = err;
    }
    // console.log(ok, refreshToken, token);
  };

  // error handling
  const errorList = [];

  if (emailError) {
    errorList.push(emailError);
  }

  if (passwordError) {
    errorList.push(passwordError);
  }
  return (
    <Container text>
      <Header as="h2">Login</Header>
      <Form>
        <Form.Field error={!!emailError}>
          <Input
            name="email"
            type="email"
            onChange={onChange}
            value={email}
            placeholder="Email"
            fluid
          />
        </Form.Field>
        <Form.Field error={!!passwordError}>
          <Input
            name="password"
            onChange={onChange}
            value={password}
            type="password"
            placeholder="Password"
            fluid
          />
        </Form.Field>
        <Button onClick={onSubmit}>Submit</Button>
      </Form>
      {errorList.length ? (
        <Message
          error
          header="There was some errors with your submission"
          list={errorList}
        />
      ) : null}
    </Container>
  );
};

export default observer(Login);
