import React from 'react';
import {
  Form,
  Message,
  Button,
  Container,
  Header,
  // eslint-disable-next-line comma-dangle
  Input,
} from 'semantic-ui-react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { State } from '../utils';

const REGISTER_USER = gql`
  mutation($email: String!, $password: String!, $username: String!) {
    register(email: $email, password: $password, username: $username) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

const ERRORS = {
  usernameError: '',
  emailError: '',
  passwordError: '',
};

const Register = (props) => {
  const [state, setState] = State({
    username: '',
    password: '',
    email: '',
    ...ERRORS,
  });

  const onChange = ({ target: { value, name } }) => {
    setState({ [name]: value });
  };

  const [register] = useMutation(REGISTER_USER);

  const onSubmit = async (e) => {
    e.preventDefault();
    setState(ERRORS);
    const { username, email, password } = state;

    const response = await register({
      variables: { username, email, password },
    });

    const { ok, errors } = response.data.register;

    if (ok) {
      // TODO: set on register
      // localStorage.setItem('token', token);
      // localStorage.setItem('refreshToken', refreshToken);
      state.errors = '';
      // eslint-disable-next-line react/prop-types
      props.history.push('/');
    } else {
      const err = {};
      errors.forEach(({ path, message }) => {
        // err['passwordError'] = 'too long..';
        err[`${path}Error`] = message;
      });

      setState(err);
    }
    // console.log(response);
  };

  const {
    username,
    email,
    password,
    usernameError,
    emailError,
    passwordError,
  } = state;

  const errorList = [];

  if (usernameError) {
    errorList.push(usernameError);
  }

  if (emailError) {
    errorList.push(emailError);
  }

  if (passwordError) {
    errorList.push(passwordError);
  }

  return (
    <Container text>
      <Header as="h2">Register</Header>
      <Form>
        <Form.Field error={!!usernameError}>
          <Input
            error={!!usernameError}
            name="username"
            onChange={onChange}
            value={username}
            placeholder="Username"
            fluid
          />
        </Form.Field>
        <Form.Field error={!!emailError}>
          <Input
            error={!!emailError}
            name="email"
            onChange={onChange}
            value={email}
            placeholder="Email"
            fluid
          />
        </Form.Field>
        <Form.Field error={!!passwordError}>
          <Input
            error={!!passwordError}
            name="password"
            onChange={onChange}
            value={password}
            type="password"
            placeholder="Password"
            fluid
          />
        </Form.Field>
        <Button onClick={onSubmit}>Submit</Button>
      </Form>
      {errorList.length ? (
        <Message
          error
          header="There was some errors with your submission"
          list={errorList}
        />
      ) : null}
    </Container>
  );
};

export default Register;
