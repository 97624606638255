import React from 'react';
import PropTypes from 'prop-types';

const Home = ({ allUsers }) => (
  <div>
    {allUsers.map((user) => (
      <div key={user.id}>{user.email}</div>
    ))}
  </div>
);

export default Home;

Home.defaultProps = {
  allUsers: [],
};

Home.propTypes = {
  allUsers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};
