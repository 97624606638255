import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import Home from './Home';
import Register from './Register';
import Login from './Login';
import CreateTeam from './CreateTeam';
import PrivateRoute from '../utils/PrivateRoute';

const ALL_USERS = gql`
  {
    allUsers {
      id
      username
      email
    }
  }
`;

export default () => {
  const { loading, data } = useQuery(ALL_USERS);

  return loading ? (
    <p>Loading...</p>
  ) : (
    <Router>
      <Switch>
        <PrivateRoute
          path="/"
          exact
          component={Home}
          propsToComponent={{ allUsers: data.allUsers }}
        />
        <Route path="/register" exact component={Register} />
        <PrivateRoute path="/create-team" exact component={CreateTeam} />
        <Route path="/login" exact component={Login} />
      </Switch>
    </Router>
  );
};
