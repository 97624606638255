import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  Message,
  Form,
  Button,
  Container,
  Header,
  // eslint-disable-next-line comma-dangle
  Input,
} from 'semantic-ui-react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const CREATE_TEAM = gql`
  mutation($name: String!) {
    createTeam(name: $name) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

const CreateTeam = (props) => {
  // state from mobx
  const state = useLocalStore(() => ({ name: '', errors: {} }));
  const [createTeam] = useMutation(CREATE_TEAM);

  const {
    name,
    errors: { nameError },
  } = state;

  const { history } = props;

  const onChange = ({ target }) => {
    const { name: _name, value } = target;
    // eslint-disable-next-line no-underscore-dangle
    state[_name] = value;
  };

  const onSubmit = async () => {
    let response = null;
    try {
      response = await createTeam({
        variables: { name },
      });
    } catch (err) {
      history.push('/login');
      return;
    }
    const { ok, errors } = response.data.createTeam;
    if (ok) {
      // eslint-disable-next-line react/prop-types
      history.push('/');
      state.errors = {};
    } else {
      const err = {};
      errors.forEach(({ path, message }) => {
        err[`${path}Error`] = message;
      });
      state.errors = err;
    }
    // console.log(ok, refreshToken, token);
  };

  // error handling
  const errorList = [];

  if (nameError) {
    errorList.push(nameError);
  }

  return (
    <Container text>
      <Header as="h2">Create a team</Header>
      <Form>
        <Form.Field error={!!nameError}>
          <Input
            name="name"
            type="text"
            onChange={onChange}
            value={name}
            placeholder="Name"
            fluid
          />
        </Form.Field>
        <Button onClick={onSubmit}>Submit</Button>
      </Form>
      {errorList.length ? (
        <Message
          error
          header="There was some errors with your submission"
          list={errorList}
        />
      ) : null}
    </Container>
  );
};

export default observer(CreateTeam);
