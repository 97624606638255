import React from 'react';
import decode from 'jwt-decode';

const State = (INITIAL_STATE) => {
  const [state, setState] = React.useState(INITIAL_STATE);
  return [state, (_state) => setState({ ...state, ..._state })];
};

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    decode(token);
    decode(refreshToken);
  } catch (err) {
    return false;
  }

  return true;
};

export {
  isAuthenticated,
  State
};
